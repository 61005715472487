<template>
         		
        <div  tabindex="0">
        	
<!--@focus="handleFocus" @focusout.stop.prevent="handleFocusOut"-->

						<div class="mt-0" v-if="user && user._id"> 

							<table v-if="user && user._id && state=='idle'" class="w-100" >
								
								<tr>
									<td width="10%" valign="middle">

										<base-avatar :user="user" :class="'tablepic round mr-2 my-auto'" :id="'profilepic'" > </base-avatar></td>
									<td width="80%" valign="middle">

										<span style="word-break: break-all;">{{username(user)}}</span> 
									</td>
									<td width="10%" valign="middle">
										<div v-if="canwrite">
									  	<b-dropdown @focus="gotfocus" id="dropdownPickowner" text="..." dropleft class="text-decoration-none" variant="secondary2" no-caret offset="25">
									  		<template #button-content>
										      <i class="far fa-bars"></i>
										    </template>
									    	<b-dropdown-item v-if="( user.status=='risk' || user.status=='masterplan')" @click="userinteraction( 'edituser', user )">Edit</b-dropdown-item>
									    	<b-dropdown-item v-if="user.status!='deleted' && ( ( ( user.status=='risk' || user.status=='masterplan') && user.details) || ( ( user.status!='risk' && user.status!='masterplan') && !isboardusers(user._id)) )" @click="userinteraction( 'addusertoboard', user, activerisk )">Add user to project</b-dropdown-item>
									    	<b-dropdown-item v-if="user.status!='deleted' && ( ( user.status=='risk' || user.status=='masterplan') && ( user.externalemail && !user.details ) || ( ( user.status!='risk' && user.status!='masterplan') && user.status!='active' && !user.details && !isboardusers(user._id)) )" @click="userinteraction( 'inviteusertoboard', user, activerisk )">  Invite user to project</b-dropdown-item>
									    	<b-dropdown-item v-if="1==2 && isboardusers(user._id)" @click="userinteraction( 'removefromboard', user )">Remove user from project</b-dropdown-item>
									    	<b-dropdown-item :class="'text-danger'" @click="userinteraction( 'removeowner', user )">Remove</b-dropdown-item>
									  	</b-dropdown>
										</div>
									</td>
								</tr>
							</table>

					<div class="w-100" v-if="state=='edituser'"> 
            <div class="mb-2">

              <input type="text" class="form-control form-control-sm" :placeholder="userfirstnameplaceholder" v-model="userfirstname" maxlength="70">
              <span class="text-danger" v-if="errormessage == 'nameinvalid'"><small>Name is required</small></span>
            </div>
            <div class="mb-2 text-right">
              <button class="btn btn-secondary" @click.stop.prevent="cancelsavecontact()">Cancel</button> <button class="btn btn-primary" @click.stop.prevent="savecontact( user )" v-if="userfirstname != '' && validateEmail(userexternalemail) == true && emailisworking==false">Save</button>
              <button class="btn btn-primary" type="button" v-if="userfirstname == '' || validateEmail(userexternalemail) == false || emailisworking==true" disabled="disabled">Save</button>
            </div>
          </div>

					 	</div>

					 <div id="search-objusers" v-if="state=='idle' && canwrite && ( user && !user._id )">	

						<input v-if="isdisabled==true" disabled="disabled" type="text"   style="width:260px;" class="vs__search clicktoadd"  placeholder="Click to search and add...">

					 	<membersearch 
					 		v-if="isdisabled==false"
					 		:addbutton="true"
					 		:mode="mode"
					 		:placeholder2="'Click to add more contacts'"
					 		@addascontact="addthiscontact"
					 		@handlefocusout="handlefocusout"
					 		@onsearchonkeyup="onsearchonkeyup"
						 	@onupdated="onupdated"
						 	@availableusersupdate="availableusersupdate"
						 	@userSelect ="userSelect"
						 	@oninviteuser ="inviteuser"
						 	@onsearchactive="searchactive"
						 	:users = "users"
						 	:availableusers="availableusers"
						 	:alreadyonlist ="alreadyonlist"
						 	:sendaninvite="sendaninvite"
						 	:isactive ="isactive"
						 	:allowadduser="allowadduser"
						 	:adminrole="adminrole"
						 	:myrole="myrole"
						 	:isadmanaged="isadmanaged"
						 	:username="username"
						 	:allowsendaninvite="false"
						 	:searchquery="searchquery"
						 	:limittoboard="limittoboard"
						 	/>

              <input style="width:270px;" type="text" class="hide form-control form-control-sm" placeholder="Add a new contact" v-model="searchtext"> <button class="btn btn-primary hide" type="submit">Add</button>
            
              </div>

        </div>
</template>
<style scoped>


	*:focus {
    outline: none;
	}

	.dropdown-toggle{
		 outline: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}

	.btn:focus, .btn.focus {
	    outline: 0;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}

	
	.vs__selected{
	  display: none !important;
	}

	#search-objusers{

		height: 45px;
	}

/*
#search-objusers, #search-objusers-objectives {
    border: 1px solid #d5dae3;
    border-radius: 0px;
    background-color: #d5dae3;
}
	#search-objusers, #search-objusers-objectives {
	    border: none;
	    border-radius: 0px;
	    background-color: #ffffff;
	}*/

	.clicktoadd{
		background-color: #d5dae3;
	}

</style>
<script type="text/javascript"></script>
<script>

	import Usersapi from '@/services/api/users'
	import Teamsapi from '@/services/api/teams'
	import Cockpitsapi from '@/services/api/cockpits'

	import membersearch from '@/components/members/membersearch'
	import vueselect from '@/views/tools/vueselect'

	import emailField from '@/components/fields/emailField.vue'
	

	export default{
		name: 'ownerPicker',
		data(){
			return{
				emailisworking:false,
				userfound:false,
				userfirstnametemp:"",
				userfirstnameplaceholder:"Name",
				selectedtext:"",
				searchtext:"",
				adminrole: "Member",
				defaultrole: "Member",
				availableusers:[],
				alreadyonlist:false,
				sendaninvite :false,
				sendaninvitetouser:"",
				isactive:true,
				isadmanaged:false,
				myrole:"Member",
				mode:"idle",
				users: [],
				searchquery:"",
				userfirstname:"",
				userexternalemail:"",
				state:"idle",
				errormessage:"",
				activerisk:{}
			}
		},

		props:{
			isdisabled: {
				type: Boolean,
				default: false
			},
			boardusers:Array,
			canwrite:Boolean,
			team:Object,
			activeriskobject:Object,
			limittoboard:String	
		},

		created(){
	  },

	  mounted() {


	  	this.$root.$on('bv::dropdown::hidden', bvEvent => {
      	$('#profilepic').focus()
    	})  	



	  	this.activerisk = this.activeriskobject
	  	//this.user = this.activerisk ? this.activerisk.owner : {}

	  },

	  watch:{

	  	/*userfirstname : function( e,v ){

	  		if( e.length > 10 ){

	  			alert('')
	  			e = "xxxxxxxxxx"
	  		}
	  	},
*/
	  	activeriskobject : function( e,v ){

	  		this.activerisk = e
	  		//this.user = this.activerisk ? this.activerisk.owner : {}

	  		if( this.user ){
		  		this.userfirstname = this.user.firstname + " " + this.user.lastname
		  		this.userexternalemail = this.user.externalemail
	  		}


	  	}

	  },

		computed:{

			user(){

				return this.activerisk ? this.activerisk.owner : {}
			},


			exemptids(){

				//console.log( this.activerisk.contacts, "exemptids")

				var ids = []
				for( var i in this.activerisk.contacts ){
					ids.push( this.activerisk.contacts[i]._id )
				}
				return ids
			},

			allowadduser(){
				return true
				//return this.myrole == this.adminrole ? true : false
			},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	
		    
      token(){
        return this.$store.getters.token
      },


		},

		methods:{

			emailworking( value ){
				this.emailisworking = value
			},

			updateExternalEmail( newvalue ){

				this.userexternalemail = newvalue
			},

			cancelsuggestion(){

				if( this.userfound == true ){

					this.userfirstname = this.userfirstnametemp
					this.userfirstnametemp = ""
					this.userfound = false

				}
				
			},

			suggestuser( user ){

				if( this.userfirstname != user.firstname + " " + user.lastname ){

					this.userfirstnametemp = this.userfirstname
					this.userfirstname = user.firstname + " " + user.lastname
					this.userfound = true	

				}else{

					this.userfirstnametemp = ""
					this.userfound = false
				}



			},

			gotfocus(){

				alert('')
			},

			handleFocus(){


				console.log('in')
			},

			handlefocusout( e ){

				let self = this

				setTimeout( function(){
					self.searchtext = ""
					self.mode = 'idle'
					self.availableusers = []
				},100)

			},

			newcontact(){

				let self = this

				this.mode = 'addnew'

				/*setTimeout( function(){

					if( self.$refs ){
						self.$refs.inputinlinesearch.focus()
					}		

				},100)*/

			},

			validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());
    			
			},	


			userinteraction( action, user, state ){


				if( action == 'edituser' ){

					this.userfirstname = this.user.firstname
					this.userexternalemail = this.user.externalemail
					this.state = 'edituser'
				}

				if( action == 'switchstate' ){
					this.switchstate( user, state )
				}

				if( action == 'savecontact' ){
					this.savecontact( user, state )
				}

				if( action == 'addusertoboard' ){

					if( user.details ){
						this.$emit('onupdaterisk', this.activerisk, 'addusertoboard', { newid:user.details._id, boardid:state.boardid } )
					}else{
						this.$emit('onupdaterisk', this.activerisk, 'addusertoboard', { newid:user._id, boardid:state.boardid } )
					}
					
				}

				if( action == 'inviteusertoboard' ){
					this.$emit('onupdaterisk', this.activerisk, 'inviteusertoboard', { newid:user._id, boardid:state.boardid, "status":"invited" } )
				}

				if( action == 'removeowner' ){
					this.$emit('onupdaterisk', this.activerisk, 'removeowner', { "cid":"",newid:user._id } )
				}

				if( action == 'removefromboard' ){

					this.$emit('onupdaterisk', this.activerisk, 'removefromboard', { force:true, boardid:this.activerisk.boardid, userid:user._id } )
				}
				 

			},

			cancelsavecontact(){

				this.userfirstname = ""
				this.userexternalemail = ""
				this.userlastname = ""

				this.state = 'idle'
			},
			savecontact( user, state ){

				let self = this

				//user.firstname = this.userfirstname
				//user.externalemail = this.userexternalemail

				if( this.userfirstname != "" ){

					if( this.userexternalemail ){

						let isvalid = this.validateEmail( this.userexternalemail )

						if( isvalid ){

							this.$emit('onupdaterisk', this.activerisk, 'owneruser', { _id:user._id, firstname:this.userfirstname, lastname:"" ,externalemail:this.userexternalemail }, function( response ){

									self.activerisk = self.activeriskobject
									//self.user = self.activerisk ? self.activerisk.owner : {}
									self.state = "idle"

							})

						}else{


							self.state = "edituser"
							self.errormessage = "emailinvalid"

						}
						

					}else{


						this.$emit('onupdaterisk', this.activerisk, 'owneruser', { _id:user._id, firstname:this.userfirstname, lastname:"" ,externalemail:"" }, function( response ){



							

						})


						self.state = "idle"

					}


				}else{

					self.state = "editcontact"
					self.errormessage = "nameinvalid"

				}

				//@click.stop.prevent="updaterisk( activecontact, activerisk,'contacts',  )

				
			},

			switchstate( user, state ){

				for( var i in state.users ){
					if( state.users[i] ){
						state.users[i].state = 'idle'
					}
				}

				user.state = state.state

			},

			onsearchonkeyup( e ){


					if ( (e.key === 'Enter' || e.keyCode === 13) && e.target ) {

								this.addthiscontact(e.target.value)

								//this.searchtext = ""

								//this.searchtext = ""
								//this.mode = 'idle'
								
					}
			},

			oninputsubmit(){

			

				//console.log( e.key,e.keyCode,e.currentTarget.value )

				 //if (e.key === 'Enter' || e.keyCode === 13) {

				 	this.addthiscontact(this.searchtext)
				 	
				 //}

				

			},


			isboardusers( userid ){

        let toreturn = false

        if( this.boardusers ){

          if( this.boardusers.indexOf( userid ) > -1){
            toreturn = true
          }

        }

        return toreturn
      },

			

			validateEmail(email) {

					let toreturn = false

					if( email != "" ){
						const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    				toreturn = re.test(String(email).toLowerCase());
					}else{
						toreturn = true
					}

					return true
    			
			},			

			inviteusertoflowe( user ){

			},

			isfloweuser( email ){

				let self = this

        Usersapi.Detailsfromemail( this.token, email )
        .then( function(response){

          if( response.data.ok ){
          	self.details = response.data.user
          }

        })
        .catch(error => console.log(error))
        .finally( () => {
        })


			},

			updaterisk( contact, sh, act, value ){

				 this.$emit('onupdaterisk', sh, act, value)
				 //this.activecontact = {}
				 contact.state = 'idle'
			},

			deselectContact( contact ){

				$('.contact-selected').removeClass('contact-selected')
				contact.state = 'idle'

			},

			selectContact( contact , ev ){


				if( contact.status == 'risk' ){


					var card = ev.currentTarget

					var iseditingsomewhere = 0

					if( this.activerisk.contacts ){

						for( var i in this.activerisk.contacts ){

							if( this.activerisk.contacts[i] != contact && this.activerisk.contacts[i].state == 'editing' ){

								iseditingsomewhere += 1
								
							}

						}
					}

					if( contact.state == 'idle' && iseditingsomewhere == 0 ){

						$('.contact-selected').not(card).removeClass('contact-selected')
						$(card).toggleClass('contact-selected')

						contact.state = 'editing'


					}


				}



			},


			editcontact( cn, field, value, el ){

				var input = $('<input type="text" class="form-control"></input>')

				input.value = "xxxx"

			},

			deletecontact( sh, contact ){


			},

			addthiscontact( firstname ){


				this.$emit('onupdaterisk', this.activerisk, 'addowner', { _id:'createnewcid', firstname:firstname, lastname:"" ,externalemail:"" } )

				this.searchtext = ""

			},

			username(user){
				let username = ''
	          	

	          	if( user.firstname ){
	          		username += user.firstname ? user.firstname:'';
	          	}

	          	if( user.lastname ){
	          		username += user.lastname ? ` ${user.lastname}`:'';
	          	}
	          	

	          	if( user.email ){
	          		username += !username && user.email ? user.email:'';
	          	}
	          	

	          	return username
			},

			searchactive(){

				/*var teamusers = Teamsapi.Getusers( this.$store.getters.token, this.team._id )
				teamusers.then( function( response ){

					console.log( response )
				})*/
				console.log('searchactive')
			},

			onupdated(){
			}, 
			availableusersupdate( search, loading ){

		        let self = this


		        if( this.isdisabled ){
		        	return false
		        }


		        this.alreadyonlist = false

		        if( this.searchbounce ){
		          clearTimeout(this.searchbounce)
		        }

		        this.searchbounce = setTimeout( function(){

		          if( search ){
		            self.searchquery = search
		          }        

		          if( search && search.length > 1 ){

		            loading(true)


		            Cockpitsapi.Getusers( search, self.exemptids, self.orgkey, false, self.limittoboard )
		            .then( function(response){   

		              if( response.data ){

		                self.sendaninvite = response.data.sendaninvite
		                self.alreadyonlist = response.data.alreadyonlist

		                if( self.sendaninvite == true ){
		                  self.sendaninvitetouser = response.data.inviteuser
		                }                

		                self.userexists =  response.data.userexists   

		                self.availableusers = response.data.users

		                self.availableusers.find(item => item._id === self.userexists._id).userexist = true


		              //console.log(self.availableusers)            
		            }

		          })
		            .catch( function(){

		              //self.cockpitsettingsmodalshown = false
		            })
		            .finally( () => {
		              loading(false)
		              //self.ajustlengthdown()

		             //ajustlengthup
		           })

		          }else{

		            self.availableusers = []
		            //self.ajustlengthdown()
		            self.searchquery = ""

		          }

		        }, 500)
		        

		      }, 

		      inviteuser(isresend , user ){

		      	this.searchtext = ""
		      	this.searchquery = ""

		    	//alert( isresend )		    	

			   	 		let self = this

		         var email = this.sendaninvitetouser

		         var str1 = 'Invite sent'

		         if( isresend ){

		              var str = ""
		              //Emailsapi.Invitationemail( user, email, context)
		              Emailsapi.Invitationemail( self.loggedinuser._id, user.email, self.orgkey)

		              /*
		              We have re-sent the invitation email to the address you indicated. Once they sign up, they will automatically have the accesses you have indicated in the members list.*/
		              str = "We have sent an invitation to the email address you indicated."
					  

		              setTimeout( function(){
		                $('.vs__search')[0].blur()
		              }, 100)

		               		               
		              bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})

		         }else{		         

		         	Userapi.Registeruser( email, this.$store.getters.token , this.orgkey ).then( function(response){

			            
			            if( response.data.ok ){

			              self.userSelect( response.data.user , true, true )

			              var str = ""

			              Emailsapi.Invitationemail( self.loggedinuser._id, email, self.orgkey)

			              str = "We have sent an invitation to the email address you indicated."
						  

			              setTimeout( function(){
			                $('.vs__search')[0].blur()
			              }, 100)

			               
			               
			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:true, sup:''})
			                

			            }else{

			              var str = "Failed to send an invitation email to the address you indicated."

			               bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:true, sup: ''})

			            }
			        }).catch( function( err ){

			        	console.log("rollback")

			        })

		      	}

		         
		    },

		    userselectwarningproceed(){

		    	this.addnoaccesstolist = false
		    	this.userSelect( this.activeoptionselected , true )

		    },


			userSelect( option , confirm ){
				
					if( option || confirm == true ){


						this.$emit('onupdaterisk', this.activerisk, 'addusertoboard', { newid:option._id, boardid: this.activerisk.boardid } )

						/*

if( user.details ){
						this.$emit('onupdaterisk', this.activerisk, 'addusertoboard', { newid:user.details._id, boardid:state.boardid } )
					}else{
						
					}

						*/
						//let newid = option.details ? option.details._id : option._id
						//this.$emit('onupdaterisk', this.activerisk, 'setowner', { "cid":option._id, "newid":newid   } )

					}

			},

		},
		components: {
			 vueselect, membersearch, emailField
			
		}

	}
</script>