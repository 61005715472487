<script type="text/javascript"></script>

<template>  


	<div class="editlink " :id="rowkey">

	  <label class="form-label m-2">{{ item.name }} <a href="#" v-if="editmode!=rowkey && canwrite" class="editicon" @click.stop.prevent="toeditmode(rowkey, item.size)"><i class="ml-2 far fa-pencil"></i></a></label>
	  <template v-if="editmode==rowkey">
	  <div class="highlight p-2 border rounded ml-2 mr-2">
	  <b-form-checkbox class="checks" v-model="selection" :value="option._id" v-for="(option,key2) in item.options" :key="key2" >{{ option.name }}</b-form-checkbox>
	  <input type="hidden" :name="inputname" :value="selection" @input="selectionupdated">

	  <template v-for="(option,key3) in tempmissingselection">
	  <b-form-checkbox v-if="missingselection.indexOf(option)>-1" v-model="missingselection" :value="option" :key="'missing'+key3" @input="uncheck(option._id)">{{ option.name }}</b-form-checkbox>
	  <b-form-checkbox v-else v-model="missingselection" disabled :value="option" :key="'uncheckedmissing'+key3"><s>{{ option.name }}</s></b-form-checkbox>


	  </template>
	  </div>
	  <div class="text-right mt-2 mr-2">


	   <button class="btn btn-primary btn-sm" type="button" @click.stop.prevent="doneedit">Done</button>
	  </div>
	  
	  </template>
	  <template v-else>
	  	
	  	<div @click.stop.prevent="toeditmode(rowkey, item.size)" class="ml-2 mr-2 mb-2">
			<ul class="border p-2 mb-0 rounded" style="list-style-type: none; min-height: 45px; line-height: 15px; ">
				<template v-for="(item,key3) in selectionsorted">
				<li class="mb-2 mt-2" :key="key3">{{ getText(item) }}</li>
				</template>
			</ul>
		</div>
		</template>

	</div>
</template> 

<style type="text/css">
	
	.list-basic{
		list-style: none;
		list-style-type: none;
	}

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'
	import {bus} from '@/main'

	export default{
		name:'pCheck',
		props:{
			canwrite:Boolean,
			viewmode:String,
			projectid:String,
			item:Object,
			editmode:String,
			rowkey:String
		},

		data(){
			return{
				portfolios:[],
				name:"",
				error:"",
				mode:"",
				formupdated:{},
				selection:[],
				missingselection:[],
				tempmissingselection:[],
				parameter:{},
				oldparameter:{},
				checked:true,
				inputname:null,
				selectionsorted:[]
			}
		},

		 mounted() {
		 	 this.getchecked()
		 },

		watch:{

			selection(){
				this.withchange()
			}			
		},
		methods:{

			toselectionsorted(){

				let self = this

				this.selectionsorted = []

				let selecteditems = this.selection.slice()

				let toreturn = []

				if( this.item.options ){

					this.item.options.forEach( function( item ){

						const index = selecteditems.indexOf(item._id)

						if( index > -1 ){
							toreturn.push(item._id)
							selecteditems.splice(index, 1);
						}

					})

				}

				//if( toreturn.length > 0 ){
				this.selectionsorted = toreturn.concat(selecteditems)
				//}else{
				//	return self.selection
				//}

				//this.item.options
				//return this.selection
			},

			selectionupdated( ){
				
			},

			clickselection2( id ){

				let selection = this.selection.slice()

				const index = selection.indexOf(id);
				if (index > -1) { // only splice array when item is found
				  selection.splice(index, 1); // 2nd parameter means remove one item only
				}else{
				  selection.push(id)
				}

				this.$emit('onitemclick', selection, this.item._id, this.projectid)
				//console.log( selection , "xxxxxxx")
			},

			uncheck( element ){

				//this.selection.unshift(element)

				const index = this.selection.indexOf(element);
				if (index > -1) { // only splice array when item is found
				  this.selection.splice(index, 1); // 2nd parameter means remove one item only
				}

			},

			withchange(){

				let self = this

				this.$nextTick(function() {
					self.inputname = this.item._id
					self.$emit('onselectionupdated')
				})
				
			},

			doneedit(){

				this.toselectionsorted()
				
				$('.stillediting').removeClass('stillediting')
				this.$emit('oneditmode', '')
			},

			toeditmode( toedit , size ){

				if( !this.canwrite ){

					let toview = document.getElementById(this.editmode)

					if( toview ){
						toview.classList.add("stillediting");
						toview.scrollIntoView();
					}
					
					return false
				}

				this.$emit('oneditmode', toedit, size )
			},

			doselect( option ){

				this.checked = false
				this.$emit('onformsaved', {"value":this.selection.join(','), "parameterid":this.item._id} )

			},

			selectionupdated(){

				
			},

			getText( optionvalue ){

				let self = this
				let toreturn = ""

				let found = 0

				let paramvalue = null

				if( this.parameter.options ){
					paramvalue = this.parameter.options.find((item) => item._id === optionvalue);
				}
				

				if( !paramvalue ){
					if( this.oldparameter && this.options ){
						paramvalue = this.oldparameter.options.find((item) => item._id === optionvalue)
					}					
				}

				if( !paramvalue ){
					paramvalue = this.item.options.find((item) => item._id === optionvalue)
				}


				if( paramvalue ){
					toreturn = paramvalue.name
				}

				return toreturn// == '' ? optionvalue : toreturn
			},

			getchecked3(){

				console.log( this.item.formvalue.values , "xxxxxxxxxx")
			},
			getchecked(){

				let self = this

				if( this.item && this.item._id ){

					Portfolioapi.getFormvalue( { parameterid:this.item._id, projectid:this.projectid, token:this.token, context:this.orgkey } ).then( function( result ){

		                    if( result.data.ok ){

		                    	let values = result.data.formvalue.values
				            	self.parameter = result.data.parameter
				            	self.oldparameter = result.data.formvalue.parameter
				            	self.selection = values ? values : []
		                    	//self.itemvalue = result.data.formvalue.value
		                    	self.getmissingselection()

		                    	self.toselectionsorted()
		                    }
	                })

				}

				

			},

			getmissingselection(){

				let self = this

				let missingselection = []
				this.selection.forEach( function( option ){
					//toreturn.push( option )

					const item = self.parameter.options.find((item) => item._id === option);

					if(item==null){
						const item2 = self.oldparameter.options.find((item) => item._id === option);
						if( item2 && item2.name ){
							missingselection.push( item2 )
						}
						
					}
				})

				this.missingselection =  missingselection
				this.tempmissingselection = missingselection.slice()
			},
			
		},

		computed:{			

			
			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},

		components:{}
	}
	</script>