<script type="text/javascript"></script>

<template>
	<div>
	<b-modal size="md" v-model="trydeleteparam" id="deleteparam-confirmation" title="Settings" no-close-on-backdrop sidebar-class="sidebarglobal" >

		<template v-slot:modal-header>
		<h3 class="modal-title">Delete parameter</h3>
		<a class = "close-modal dialog-close-bustaketton mr-2">
		<i class="fal fa-times fa-lg" @click.stop.prevent="deleteparam=''"></i>
		</a>
		</template>

		<div>			
			Do you want to proceed with deleting this parameter? Deleting it will permanently remove the parameter, and any projects linked to it will lose the corresponding values. This action cannot be undone.
		</div>

		<template v-slot:modal-footer="{ cancel, ok }">

		<button type="button" class="btn btn-secondary mr-2" @click.stop.prevent="deleteparam=''">Cancel</button>
		<button type="submit" class="btn btn-danger" @click.stop.prevent="removeparameter(activerow)">Delete parameter</button>

		</template>

	</b-modal>
	<b-modal size="md" v-model="opensettings" id="sidebar-right" title="Settings" hide-footer no-close-on-backdrop @shown="onshown" @hidden="onhidden" sidebar-class="sidebarglobal" >

		<template v-slot:modal-header>
		<h3 class="modal-title">Settings</h3>
		<a class = "close-modal dialog-close-bustaketton mr-2">
		<i class="fal fa-times fa-lg" @click.stop.prevent="closemodal"></i>
		</a>
		</template>

        <i v-if="!rows" class="fa fa-circle-o-notch fa-spin m-auto" style="font-size: 18px;"/>

        <div v-else @click.stop.prevent="cancelevents">
		
		<div v-if="!activerow._id && mode=='addnewparameter'"  >

			<div class="d-flex align-items-center">
				
				<div v-if="activerow._id || mode=='addnewparameter'" class="p-2">
                    <a  href="#" @click.stop.prevent="goback()"><i class="far fa-arrow-left gobackicon"></i></a>
                </div>

                <div class="h3like">Add parameter</div>
			</div>

			

			<div class="p-2">
				<label>Name</label>
				<input placeholder="Parameter name" type="text" class="form-control active-row" v-model="addparametertext">
			</div>

			<div class="p-2 mb-2">
				<label>Type</label>
				<div>
				<b-dropdown no-caret id="parameterType" :text="selectiontext(addparametersize)" class="w-100" variant="gray" >

					<template #button-content>
						<div class="d-flex"  >
						  <div class="flex-grow-1 paramitem">	

						  <formicon :icon="addparametersize" :showtext="true"></formicon>					  	
						  </div>
						  <div class="">
						  	<i class="fa-solid fa-caret-down"></i>
						  </div>
						</div>
    				</template>

				<b-dropdown-item @click="addparametersize='small'"><i title="Text" class="form-icon fa-regular fa-input-text align-self-center"></i> Text</b-dropdown-item>
				<b-dropdown-item @click="addparametersize='rich'"><i title="Text" class="form-icon fa-regular fa-keyboard align-self-center"></i> Multiline</b-dropdown-item>
				<b-dropdown-item @click="addparametersize='selection'"><i title="Text" class="form-icon fa-regular fa-square-caret-down align-self-center"></i> Dropdown</b-dropdown-item>
				<b-dropdown-item @click="addparametersize='check'"><i title="Text" class="form-icon fa-regular fa-square-check align-self-center"></i> Checkboxes</b-dropdown-item>
			</b-dropdown>
			</div>
			</div>

			<div class="mb-2 p-2">Note: A parameter’s type cannot be changed once it has been created.</div>

			<div class="text-right">

				<button class="btn btn-secondary mr-2" @click.stop.prevent="goback()">Cancel</button>
				<button :disabled="!addparametertext || !addparametersize" class="btn btn-primary" @click="addparameter($event)">Add parameter</button>
			</div>
		
		</div>

		<template v-if="activerow._id">

			<div class="d-flex align-items-center">
				
				<div v-if="activerow._id || mode=='addnewparameter'" class="p-2">
                    <a  href="#" @click.stop.prevent="goback()"><i class="far fa-arrow-left gobackicon"></i></a>
                </div>

                <div class="h3like flex-grow-1">Edit parameter</div>


                <template v-if="!activerow.protected">
                	<button v-if="deleteparam!=activerow._id" @click.stop.prevent="deleteparam=activerow._id" class="btn btn-danger">Delete parameter</button>
                </template>
			</div>




		<form id="parameterdetails" onsubmit="return false">

			<div class="p-2">

  				<label>Parameter type</label>
  				
  				<div class="form-control">
  				<formicon :icon="activerow.size" :showtext="true"></formicon>
  				</div>

  			</div>

  			<div class="p-2">

  				<label>Name</label>
  				
  				<pInput :type="'parameter'" :row="activerow" :rownum="1" @onchange="updateparametername($event)"></pInput>

  			</div>
		  			
  			<div class="p-2" v-if="activerow.size=='selection' || activerow.size=='check' || activerow.size=='phase'">

  				<label>Options</label>

  				<div id="parameteroptions" >

				<template v-for="( option, key2 ) in origlist"> 
		  		<div :data-id="option._id" class="pt-1 pb-1 pl-1 pr-1 paramrowoption" :key="'paramrowoption_'+key2">
		  			<div class="d-flex align-items-center">

		  				<div class="ogrip m-2"><i class="fas fa-grip-lines"></i></div>
		  				
		  				<pInput :type="'option'" :row="option" :rownum="key2" @onchange="updateoptionname($event, option, activerow)"></pInput>

		  				<div class="pl-1 pr-1" style="width: 45px;">

		  					<div class="d-flex align-items-center">
		  					<button v-if="deleteoption==key2+1" class="btn btn-danger btn-sm" @click.stop.prevent="removeoption( option._id, key2)">Confirm</button>
		  					<a class="delbutton" v-if="deleteoption!=key2+1" href="#" @click.stop.prevent="deleteoption=key2+1"><i class="redcircle fas fa-minus-circle"></i></a>
		  					</div>

		  					

		  				</div>
		  			</div>
		  		</div>

		  		</template>

		  		<div class="pt-1 pb-1 pl-1 pr-1 paramrowoption" >
		  			<div class="d-flex align-items-center">

		  				<div class="ogrip2 m-2" style="visibility: hidden;"><i class="fas fa-grip-lines"></i></div>
		  				
		  				<input type="text" class="form-control active-row" @change="addnewoption" maxlength="70" placeholder="New option" ref="addnewoptioninput" title="Press enter to submit" >

		  				<div class="pl-1 pr-1 text-center" style="width: 45px;">
		  					<a href="#"><i class="fas fa-plus-circle tryaddnewoptionicon"></i></a>
		  				</div>
		  			</div>
		  		</div>

		  		<div class="pt-2 pb-2 pr-0 active-row" v-if="1==2">
		  			<a v-if="tryaddnewoption" style="cursor: default" href="#" class="disabled text-disabled">Add new option</a>
		  			<a v-else href="#" @click.stop.prevent="newoption()" tabindex="0" class="addnewoptionlink" ref="addnewoptionlink">Add new option</a>
		  		</div>
			</div>

			
		</div>

		</form>

		
					

		</template>

		<template v-if="!activerow._id && mode == ''">



		<div class="d-flex align-items-center mb-2">
			<h3 class="flex-grow-1 mb-0">
				<span v-if="portfolio.active" class="fw-bold" >Parameters</span>
			</h3>
			<div>

				<button @click.stop.prevent="makeinactive(false)" v-if="portfolio.active" class="btn btn-warning-secondary btnmake">Make portfolio inactive</button>

				<button @click.stop.prevent="makeinactive(true)" v-if="!portfolio.active" class="btn btn-success btnmake">Make portfolio active</button>

			</div>
		</div>

		<div v-if="portfolio.active" class="mb-2">Define the parameters for this portfolio</div>

		<ul v-if="portfolio.active" class="list-group list-group-flush parameterlist mb-2" id="parameterlist">
			
				<template v-for="( row, key ) in rows"> 

			  		<li :data-id="row._id"  class="list-group-item pl-0 pr-0 paramrow" :key="'paramrow_'+key" :class="{'inactive':!row.active}"> 

			  			<div class="d-flex align-items-center pr-2 p-2">

			  				<span class="grip"><i class="fas fa-grip-lines"></i></span>

			  				<span class="form-icon" v-if="row.size=='phase'"><i class=" fa-regular fa-columns-3"></i></span>
			  				<span class="form-icon" v-if="row.size=='small'"><i class=" fa-regular fa-input-text"></i></span>
			  				<span class="form-icon" v-if="row.size=='check'"><i class=" fa-regular fa-square-check"></i></span>
			  				<span class="form-icon" v-if="row.size=='selection'"><i class=" fa-regular fa-square-caret-down"></i></span>
			  				<span class="form-icon" v-if="row.size=='rich'"><i class=" fa-regular fa-keyboard"></i></span>

			  				<span class="flex-grow-1 paramitem">

			  					<a :tabindex="key+15" class="" href="#" @click.stop.prevent="editparam(row)">
			  						<input v-if="editrow._id==row._id" type="text" class="form-control inline" :value="row.name" @change="inlinesave">
			  						<template v-if="editrow._id!=row._id">
			  						<span>{{ row.name }}</span>
			  						<i class="ml-2 far fa-pencil editicon"></i>
			  						</template>			  						
			  					</a>

			  				</span>	
			  			
			  			</div>

			  			
			  		</li>
			  		
			  	</template>


			  	<li class="list-group-item pl-0 pr-0 paramrow"> 

			  		<div class="d-flex align-items-center pr-2 p-2">

			  			<span class="grip"><i class="far fa-plus"></i></span>

			  			<span class="flex-grow-1 paramitem pl-2">
			  			
			  			<a class="" v-if="mode=='' && !activerow._id" @click.stop.prevent="tryaddnewparameter">Add parameter</a>

			  			</span>

			  		</div>
			  		</li>
			</ul>

			

			</template>
		</div>
	<template v-slot:modal-footer="{ cancel, ok }">

                    <div class="d-flex">

                        <div  v-if="deleteparam!='' && 1==2" class="d-flex align-items-center">

                            <span> This will delete all data related to this parameter including saved values.
                            </span>


                            <button type="button" class="btn btn-secondary mr-2" @click.stop.prevent="deleteparam=''">Cancel</button>
                            <button type="button" class="btn btn-danger" @click.stop.prevent="dodeleteparam(activerow)">Delete</button>
                        </div>

                        <div v-if="1==2" class="flex-grow-1 mr-2 text-left">
                            <button v-if="deleteparam==''" type="button" class="btn btn-danger" @click.stop.prevent="deleteparam='yes'" >Delete this parameter</button>
                        </div>

                        <div >
                        <button type="button" class="btn btn-secondary mr-2" @click.stop.prevent="cancelsave()">Cancel</button>
                        

                        <button v-if="mode=='addnewparameter'" type="submit" class="btn btn-primary" @click.stop.prevent="addparameter()" :disabled="addparametertext==''" >Add parameter</button>
                        <template v-else>
                        	<button type="submit" :disabled="withchanges==0" class="btn btn-primary" @click.stop.prevent="saveparametervalues()">Update parameter</button>
                        </template>
                        

                        </div>
                    </div>


                    </template>
                </b-modal>
    </div>
</template> 

<style type="text/css">

	.addnewoptionlink:focus{
		text-decoration: underline !important;
	}

	.gobackicon{
		font-size: 20px;
	}

	.btn-gray{
		border: 1px solid var(--N200) !important;
	}
	.h3like{
		font-size: 20px;
	}

	#parameterlist .grip{
		font-size: 16px;
	}

	.grip .fa-grip-lines, .ogrip .fa-grip-lines{
		/*visibility: hidden;*/
	}

	.paramrow:hover .grip .fa-grip-lines, .paramrowoption:hover .ogrip .fa-grip-lines{
		visibility: visible !important;
	}

	.paramrow:hover .delbutton, .paramrowoption:hover .delbutton{
		display: block;
	}

	.tryaddnewoptionicon{
		font-size: 16px;
	}

	.delbutton{
		display: none;
		width: 32px;
		text-align: center;
	}

	.redcircle{
		color: var(--R500);
		font-size: 16px;

	}

	.editicon{
		display: none;
	}

	.paramitem:hover .editicon{
		display: inline;
		color: #000;
	}

	.ghost {
	  opacity: 1;
	}

	.chosen{
		border-left: solid 3px;
		border-right: solid 3px;
	}

	.dragging{
		opacity: 1;
	}

	.paramrow .grip, .paramrowoption .ogrip{
		/*display: none;*/
		/*position: absolute;
		left: 5px;
		top: 8px;*/
	}

	.paramrow:hover .grip, .paramrowoption:hover .ogrip{
		display: block;
		cursor: move;
	}

	.inactive{
		/*background-color: var(--N100);*/
	}

	.badge-clickable{
		cursor: pointer !important;
	}

	.badge-simple{
		border-radius: 0 !important;
		margin: 2px;
	}
	.active-row{
		/*background-color: var(--N100) !important;*/
	}
	.parameter:hover{
		background-color: var(--N100);
	}

	.parameter .actions{
		background-color: #fff;
		padding: 5px;
		display: none;
		z-index: 99999;
	}

	.parameter:hover .actions{
		display: block;
	}

	.sortable-ghost { opacity: 0; }

</style>

<script>

	import Portfolioapi from '@/services/api/portfolio'

	import Sortable from 'sortablejs';

	import pInput from '@/views/portfolio/forms/pInput.vue'

	import formicon from '@/views/portfolio/forms/formicon.vue'

	

	import { v4 as uuidv4 } from 'uuid'


	export default{
		name:'portfolioSettings',
		props:{
			opensettings:Boolean
		},
		data(){
			return{
				nullvalue:'',
				withchanges:0,
				projectid:"",
				groupid:"",
				portfolioid:"",
				opensettingspage:false,
				temp:{},
				portfolio:{},
				project:{},
				adding:false,
				addingtype:'',
				inputtextlabel:'',
				inputtextlabelsize:'small',
				inputselectionlabel:'',
				inputselectionoption:'',
				inputselectionoptions:[],
				inputselectionsize:'selection',
				editrow:{},
				editoptionid:"",
				expandlist:"",
				editid:null,
				rows:null,
				sortable:null,
				editopts:[],
				editselectionrow:null,
				editgroup:null,
				portfoliogroups:[],
				addparametersize:"",
				addparametertext:"",
				sortableoptions:null,
				sortableparameterlist:null,
				deleteparam:"",
				deleteoption:"",
				mode:"",
				activerow:{},
				optionupdates:[],
				optionupdateids:[],
				parameternamechanged:0,
				//newoptions:[],
				//activerowoptions:[],
				origlist:[],
				tryaddnewoption:false,
				onmodalclose:null
			}
		},

		 mounted() {

		 	this.onmodalclose = null
		 	window.settingsmini=this;		 	
		 	
			
		 	
		 },

		watch:{

			activerow : function(e){

				let self = this

				if(!e._id){			

					setTimeout( function(){
						self.bindsortables()
					}, 50)
					
				}else{

					setTimeout( function(){
						self.bindsortablesinner()
					}, 100)

				}
			},

			rows : function(){
				//this.bindsortables()
			}

		},
		methods:{

			makeinactive( isactive ){

                /*let self = this 

                let update = JSON.stringify( { "active":isactive } )

                Portfolioapi.updatePortfolio( { "portfolioid":this.portfolio._id, "patch":update, "t":this.token } ).then( function( result ){

                    if( result.data.ok ){
                       self.loadportfolio( true ) 
                    }
                })*/

                this.onmodalclose = function(){
                	this.$emit('onmakeinactive', isactive)
                }	
                this.closemodal()

            },

			newoption(){

				let self = this


				this.tryaddnewoption=true

				setTimeout( function(){
					self.$refs.addnewoptioninput.focus()
				},100)				

			},

			ondrag( obj, obj1){

				obj.target.style.position = 'absolute'

				console.log( obj, obj1  )
			},

			cancelevents(){

				this.deleteoption = ""
				this.deleteparam=''

				let self = this
				if( !this.editrow._id  ){
					return false
				}

				
				this.editrow = {}
				
				

				setTimeout( function(){
					self.bindsortables()
				}, 100)

				
			},

			goback(){
				this.cancelsave()
				this.loadportfolio( true ) 
			},

			onshown(){
				this.onmodalclose = null
				this.optionupdates = []
				this.activerow = {}
		 		this.mode=''
		 		this.tryaddnewoption=false
		 		this.loadportfolio( true ) 
		 		this.$emit('onsettingsopened')	
			},

			onhidden(){
				this.cancelsave()
				this.rows = []
				this.$emit('onhidden')

				if( this.onmodalclose ){
					this.onmodalclose()
				}
				
			},

			trydeleteoption( key , list, optionid, type ){



				 /*this.addtochangesoption( optionid, type, 'delete')
				 list.splice(key, 1)
				 this.deleteoption = ""
				 this.withchanges++*/
			},

			timenow(){
				//let newdate = new Date()
				return window.performance.now()
			},

			selectiontext(size){

				let toreturn = ''

				switch(size) {
				  case 'small':
				    toreturn = "Text"
				    break;
				  case 'rich':
				    toreturn = "Multiline"
				    break;
				  case 'selection':
				    toreturn = "Dropdown"
				    break;
				  case 'check':
				    toreturn = "Checkboxes"
				    break;
				  default:
				  	toreturn = "Select one"
				}

				return toreturn

			},

			addtochangesoption( element, type, op ){

				let iid = element.target ? element.target.name : element
				let text = element.target ? element.target.value : ""

				this.withchanges++

				let index = this.optionupdateids.indexOf(iid)
				if( index == -1 ){					
					this.optionupdateids.push(iid)						
				}else{
					this.inputselectionoptions.splice(index, 1); 					
				}

				



				this.optionupdates.push( { "op":op, "type": type, "id" : iid, "name": text } )

				//this.optionupdates.push( 'xx' )
				console.log( this.optionupdates )

				//this.optionupdates

				//

				//element.target.value = 'sadasdasd'
				//console.log( element.target.name, element.target.value )

			},

			savegroupvalue(){

				let self = this

				if( this.parameternamechanged > 0 ){
					this.optionupdates.push( { "op":"update", "type":"parameter", "id" : this.activerow._id, "name": this.$refs.parametername.value } )
				}

				let updates = JSON.stringify( this.optionupdates )

				this.parameternamechanged = 0
				this.optionupdates = []

				if( updates.length > 0 ){

					Portfolioapi.Commitupdate( { "parameterid" : this.activerow._id, patch:updates, t:this.token, context:this.orgkey }  ).then( function( result ){

						if( result.data.ok ){
							self.cancelsave()
						}
					})

				}else{
					this.cancelsave()
				}

				
			},

			

			closemodal(){
				this.$emit('oncloseme')	
			},

			paramsave( form, param ){

				//param.name = form.value
				param.name = form.target.value
				//console.log( form.target.value  )
			},

			addnewoption( element ){

				let addnew = null

				let newname = "New option"

				if( element ){
					newname = element.target.value
				}

				let self = this
				let parameter = this.activerow

				Portfolioapi.addoption( { parameterid:parameter._id, name: newname, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.ok ){
								self.origlist.push( result.data.option )
								//self.rows.push( result.data.option )
							}

							self.$refs.addnewoptioninput.value = ''
							self.tryaddnewoption = false


							setTimeout( function(){
								self.$refs.addnewoptioninput.focus()
							}, 300)
							

						})

			},

			editphases( param ){

				/*this.activerow = param
				
				this.mode = 'edit'

				this.expandlist=param._id
				this.editrowid=param._id
				this.editoptionid=''*/

			},

			editparam( param ){

				this.deleteparam = ''

				//if( param.size == 'small' || param.size == 'rich' ){
				//	this.editrow = param//._id
				//	return false
				//}
				/*if( param.size == 'phase' ){
					return false
				}*/

				
				this.activerow = param

				//if( param.type!='phase' ){
					this.mode='editparam'
					this.origlist = param.options.slice()
				//	this.origlist.push({})
				//}else{
				//	this.mode='editphase'//+param.type
				//	this.origlist = this.portfoliogroups.slice()
				//}

				
				//this.activerowoptions = param.options
				/*
				
				this.origlist = param.options
				this.mode = 'edit'

				this.expandlist=param._id
				this.editrowid=param._id
				this.editoptionid=''*/

				////this.bindsortablesinner(param._id)

				this.$emit('oneditparameter', param )			

			},

			pageclick(){

				//this.editrowid = ""
				//this.editoptionid = ""
			},

			updateparametertype( select, parameter ){

				let size = select.target.value
				let type = "selection"

				if( size == "small" || size == "big" ){
					type = "input"
				}


				let update = JSON.stringify( { "size":size, "type":type } )

				Portfolioapi.updateParameter( { patch:update, parameterid:parameter._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.rows = result.data.parameters
					}
				})

			},

			setparameterasgroup( parameter, doset ){

				let self = this

				Portfolioapi.groupby( { "portfolioid":this.portfolioid, "parameterid":parameter._id, "doset":doset, "t":this.token } ).then( function( result ){

					if( result.data.ok ){
						self.rows = result.data.parameters
					}
				})

			},

			/*loadportfolioparameters(){


				let self = this

			 	this.portfolioid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

				Portfolioapi.getParameters( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){
						self.portfolio = result.data.portfolio

						setTimeout( function(){
							self.loadrows()
						}, 50)

						self.portfoliogroups = self.portfolio.groups
					}
				})


			},*/

			loadportfolio( dorefresh, callback ){

				let self = this

			 	this.portfolioid =  this.$route.params.portfolioid ? this.$route.params.portfolioid : ""

				Portfolioapi.getOne( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.portfolio ){

						if( dorefresh ){


							self.portfolio = result.data.portfolio
							self.temp = result.data.portfolio

							setTimeout( function(){
								self.loadrows( function(){
									setTimeout( function(){								
										self.bindsortables()
									}, 10)	
								})
							}, 10)

							self.portfoliogroups = self.portfolio.groups

							


						}

						if( callback ){
							callback( result.data.portfolio )
						}
						
					}
				})

			},

			doupdategroup( groupid, el ){

				let self = this

				let update = JSON.stringify( { name:el.target.value } )

				Portfolioapi.updateGroup( { patch:update, gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( true )
						self.editgroup = null
					}
				})

			},

			editselectionrowupdate( key, el, optionid ){

				//console.log( this.inputselectionoptions[key], "key" )
				//return false
				if( el.keyCode == 13 ){

					let self = this

					if( this.editid ){

						let update = JSON.stringify( { name:el.target.value } )

						Portfolioapi.updateoption( { patch:update, parameterid:this.editid, optionid:optionid, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
								self.editselectionrow = null
							}

						})

					}else{

						this.inputselectionoptions[key].value = el.target.value
						this.editselectionrow = null

					}
				}
			},

			editinplacegroup( key, el ){

				this.editgroup = key	

				setTimeout( function(){		

					var editrow = document.getElementById( 'editgroup' + key);

					if( editrow ){					
						editrow.focus()					
					}

				}, 100)

			},

			updateoptionname( input, option, parameter ){

				if( !input.target || !input.target.value || input.target.value == ''){

					if( input.target.value == '' ){

						input.target.classList.add("border-danger");
						setTimeout( function(){
							input.target.classList.remove("border-danger");
						},1000)

					}

					return false
				}

				this.tryaddnewoption = false


				let self = this
				let update = JSON.stringify( { name:input.target.value } )

				Portfolioapi.updateoption( { patch:update, parameterid:parameter._id, optionid:option._id, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( true )
						input.target.classList.add("border-success");
						setTimeout( function(){
							input.target.classList.remove("border-success");
						},1000)
					}

				})


			},


			inlinesave( input ){	

				//let input = obj.target
				let parameter = this.editrow

				/*if( input.keyCode == 27 ){
					
					this.editrowid = ""
					this.editoptionid = ""
					return false
				}


				if( input.keyCode != 13 ){
					return false
				}*/

				let self = this

				if( input.target.value != '' ){

					let update = JSON.stringify( { "name":input.target.value } )

					Portfolioapi.updateParameter( { patch:update, parameterid:parameter._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							parameter.name = result.data.parameter.name
							self.editrow = {}//id = ""
						}
					})

				}


				

			},

			editinplace( el ){

			},

			editinplace2( key, el ){

				this.editselectionrow = key	

				setTimeout( function(){		

					var editrow = document.getElementById( 'editselectionrow' + key);

					if( editrow ){					
							editrow.focus()			
						
					}

				}, 100)

			},

			bodyclick(){

				if( this.editselectionrow != null ){
					this.editselectionrow = null
				}

				if( this.editgroup != null ){
					this.editgroup = null
				}

			},

			hidegroup( group , isactive ){

				//group.active = group.active ? false : true

				let groupid = group._id
				let self = this

				Portfolioapi.activeGroup( { active:isactive, gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( false, function( portfolio ){

							portfolio.groups.forEach( function( pgroup ){

								if( pgroup._id == groupid ){
									group.active = pgroup.active
								}

							})

							//self.portfoliogroups = portfolio.groups
						})
					}
				})

			},


			removegroup( groupid , key ){

				let self = this

				Portfolioapi.deleteGroup( { gid:groupid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.origlist.splice(key, 1)
						self.deleteoption = ""
						self.withchanges++
					}
				})

			},

			removeoption( optionid , key ){

				let self = this

				Portfolioapi.removeoption( { parameterid:this.activerow._id, optionid:optionid, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.ok ){
								self.origlist.splice(key, 1)
								self.deleteoption = ""
								self.withchanges++
							}

						})


			},

			removeme( optionid ){	

				let self = this	

				if(this.editid){

					Portfolioapi.removeoption( { parameterid:this.editid, optionid:optionid, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
							}

						})

				}else{

					if( this.inputselectionoptions[optionid] ) { 
					 // this.editopts.splice(id, 1); 
					  this.inputselectionoptions.splice(optionid, 1); 
					 // this.withchanges++
					}


				}	

				

			},

		    bindsortablesinner( parameterid ){

		    	let self = this

		    	const parameteroptions = document.getElementById("parameteroptions");
		    	const parameterphases = document.getElementById("parameterphases");

		    	let element = null 
		    	
		    	let ptype = ""

		    	if( !parameteroptions && parameterphases ){
		    		element = parameterphases
		    		ptype = "phases"
		    	}else if( parameteroptions && !parameterphases ){
		    		element = parameteroptions
		    		ptype = "options"
		    	}else{
		    		return false
		    	}


		    	if( this.sortableoptions ){
		    		this.sortableoptions.destroy()
		    	}

		    	this.sortableoptions = new Sortable( element, { handle:'.ogrip', ghostClass: "ghostClass", chosenClass: "shadow", dragClass: "dragging", onSort:function( obj ){

		    				let parameteroptionsvalue = self.sortableoptions.toArray()
		    				//console.log( parameteroptionsvalue )

							let update = JSON.stringify( { sortorder:parameteroptionsvalue, type:ptype } )

                            Portfolioapi.updatesort( { patch:update, portfolioid:self.portfolioid, t:self.token, context:self.orgkey } ).then( function( result ){
									//post sort action
								})

		    			} 
		    		})

		    },

		    bindsortables(){

		    	//return false

		    	let self = this
		    	var parameterlist = document.getElementById('parameterlist');

		    	if( !parameterlist ){
		    		//$('.grip').hide()
		    		return false
		    	}else{
		    		//$('.grip').show()
		    	}

		    	if( this.sortableparameterlist ){
		    		//this.sortableparameterlist.destroy()
		    	}		    	
		    	
		    	this.sortableparameterlist = new Sortable( parameterlist, { handle:'.grip',  onSort:function( obj ){

		    				let parameterlist = self.sortableparameterlist.toArray()

		    				let update = JSON.stringify( { sortorder:parameterlist, type:'parameters' } )

                            Portfolioapi.updatesort( { patch:update, portfolioid:self.portfolioid, t:self.token, context:self.orgkey } ).then( function( result ){
									//post sort action
								})	    				


				    	} 
				    })

		    	
		    },

		    loadparameter( item ){

		    	let self = this

				Portfolioapi.getParameter( { pid: this.portfolioid, parameterid: item, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.parameter ){

						const row = result.data.parameter
						self.editid = row._id
						self.editrow = row
						self.addingtype = row.type
						self.inputtextlabel = row.name					
						self.inputtextlabelsize = row.size

						self.inputselectionlabel = row.name
						
						self.loadoptions( row.options )
						self.inputselectionsize = row.size

						setTimeout( function(){
							//self.bindsortables()
							//self.bindsortablesgroups()
						}, 4000)	

					}
				})

		    },

		    loadoptions( options ){

		    	let self = this


		    	self.inputselectionoptions = []
		    	if( options && options.length > 0 ){

		    		for( var i =0; i<options.length;i++ ){
		    			let option = options[i]
		    			let opt = { "value":option.name, "uuid":option._id }
		    			self.inputselectionoptions.push(opt)
		    		}

		    		
		    	}

		    	
		    },

			edit( item ){
				this.loadparameter( item )	
			},

			loadrows( callback ){

				if( this.portfolio ){

					let self = this

					Portfolioapi.getParameters( { pid:this.portfolioid, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

							if(callback){
								callback()
							}
						}
					})

				}

			},

			cancelsave(){

				let proceed = true

				//if( this.withchanges > 0 ){
				//	proceed = confirm('Changes will not be saved')
				//}
				

				if( !proceed ){
					return false
				}
				this.withchanges = 0

				this.activerow = {}
				this.mode = ''
				this.origlist = []


				this.addparametertext = ""
				this.addparametersize = ""

				this.tryaddnewoption = false
			},

			cancelmode(){

				this.portfolio = this.temp
				this.expandlist = ''
				this.editrow = {}
				this.editid = null
				this.mode=0
				this.withchanges=0

				//this.$router.push('detail')
				
			},

			savesettings( dosave ){

                if(!dosave){
                    this.activerow={}
                    return false
                }

            },

            saveparametervalues(){


            	let self = this

            	let updates = JSON.stringify( this.optionupdates )

				

				if( this.optionupdates.length > 0 ){

					Portfolioapi.Commitupdate( { "parameterid" : this.activerow._id, patch:updates, t:this.token, context:this.orgkey }  ).then( function( result ){

						if( result.data.ok ){
							self.parameternamechanged = 0
							self.optionupdates = []
						}
					})

				}



            	
            },

			savesettings2( size ){

				let form = document.getElementById('portfoliosettings')
                //let form = document.createElement("form");
                //form.appendChild(div);
                let formserialized = new URLSearchParams(new FormData(form))

                console.log( formserialized )

				/*let self = this

				if( this.rows.length>0 ){

					Portfolioapi.saveRows( {"portfolioid":this.portfolioid, "rows":JSON.stringify(this.rows), "token":this.toke, "size":size } ).then( function( result ){

						self.cancelmode()
					})

				}*/


				//this.cancelmode()
			},

			updateportfolio(){

				console.log( this.portfolio ) 
			},

			updateparametername( input ){

				if( !input.target || !input.target.value || input.target.value == ''){
					return false
				}

				let self = this

				let update = JSON.stringify( { name:input.target.value  } )

					Portfolioapi.updateParameter( { patch:update, parameterid:this.activerow._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){

							self.rows = result.data.parameters

							//self.updateportfolio()

							//self.loadportfolio( true )
							input.target.classList.add("border-success");
							setTimeout( function(){
								input.target.classList.remove("border-success");
							},1000)
						}
					})

			},

			updatephasename( phase, input ){

				if( !input.target || !input.target.value || input.target.value == ''){
					return false
				}

				let self = this

				let update = JSON.stringify( { name:input.target.value } )

				Portfolioapi.updateGroup( { patch:update, gid:phase._id, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

					if( result.data.ok ){
						self.loadportfolio( true )
						input.target.classList.add("border-success");
						setTimeout( function(){
							input.target.classList.remove("border-success");
						},1000)

						/*input.target.value = ""
						self.editoptionid = ""*/
					}
				})

			},

			tryaddnewparameter(){

				this.mode='addnewparameter'
				this.activerow = {}
				this.withchanges=0
			},

			/*addnewphase(){

				this.newphaseinput()

				let newgroup = {}

				newgroup.id = "newphaseid"
				newgroup.active = true
				newgroup.default = false
				newgroup.name = ""
				newgroup.sortorder = 0
				newgroup.portfolioid = ""

				this.origlist.push(newgroup)

			},*/

			removeparameter( parameter ){

				let self = this

				this.deleteparam = ''

				Portfolioapi.removeParameter( { pid:this.portfolioid, parameterid: parameter._id, token:this.token, orgkey:this.orgkey }).then( function( result ){

					if( result.data.ok && result.data.parameters ){
						self.rows = result.data.parameters
						//self.deleteparam = ""
						//self.cancelevents()
						self.goback()
					}
				})


			},

			unremoveparameter( parameter ){

				let self = this

				Portfolioapi.undoremoveParameter( { pid:this.portfolioid, parameterid: parameter._id, token:this.token, orgkey:this.orgkey }).then( function( result ){

						if( result.data.ok && result.data.parameters ){

							self.rows = result.data.parameters

						}
					})


			},


			addparameter( el ){


				let self = this

				if( this.addparametertext != '' && this.addparametersize != '' ){

					let options = []

					let datatype = "input"

					if( this.addparametersize == 'selection' || this.addparametersize == 'check' || this.addparametersize == 'option' ){
						datatype = 'selection'
					}

					if( this.addparametersize == 'rich' ){
						datatype = 'rich'
					}

					if( this.addparametersize == 'file' ){
						datatype = 'file'
					}


					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":datatype, "name":this.addparametertext, "datasize":this.addparametersize, "sortorder":0, "active":true , "protected":false, "required":false, "options":JSON.stringify( options ), "t":this.token  } ).then( function( result ){

						if( result.data.ok ){

							let withoptions = ['selection','check']
							if( withoptions.indexOf( result.data.parameter.size ) > -1  ){
								self.editparam( result.data.parameter )
							}else{

								self.rows = result.data.parameters
								self.addparametersize = ""
								self.addparametertext = ""
								
								self.mode = ''	
								self.withchanges=0
								self.cancelsave()
							}

						}			

					})

				}

				

			},

			addoption( input, parameter ){


				if( input.keyCode == 13  ){			// || forced	

					let self = this					

					if( input.target.value != '' ){

						Portfolioapi.addoption( { parameterid:parameter._id, name:input.target.value, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								input.target.value = ""
								parameter.options = result.data.parameter.options
							}

						})
					}

			}


			},

			dodeleteparam( parameter){

				let newrows = []

				this.rows.forEach( function( row ){

					if( row._id != parameter._id ){
						newrows.push(row)
					}

				})

				this.rows = newrows


			},

			dodeleteoption( parameter, option ){

				let newoptions = []
				parameter.options.forEach( function( item ){
					if( item._id != option._id ){
						newoptions.push(item)
					}
				})

				parameter.options = newoptions

				this.deleteoption = ''

				//let item = parameter.options.find((item) => item._id === option._id);

				//console.log( item, "parameter" )

				//return false

				/*Portfolioapi.removeoption( { parameterid:parameter._id, optionid:option._id, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								//self.loadoptions( result.data.parameter.options )
								parameter.options = result.data.parameter.options
							}

						})*/
			},

			addinput(){

				if( this.inputtextlabel != '' ){

					let self = this

					let options = []

					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":"input", "name":this.inputtextlabel, "datasize":this.inputtextlabelsize, "sortorder":0, "active":true , "protected":false, "required":false, "options":JSON.stringify( options ), "t":this.token  } ).then( function( result ){

						self.addingtype = ""
						self.inputtextlabel = ""

					})
				}

			},

			updatephases( groups ){

				let self = this

				Portfolioapi.saveGroups( {"portfolioid":this.portfolioid, "groups":JSON.stringify(this.editopts), "t":this.token} ).then( function( result ){

					self.addingtype = ""
					//self.getportfolio(self.pid)
		 			//self.getboards()
		 			//self.getportfolio(self.pid)
		 			//self.opensettings = false
				})

			},


			updateselection(){

				if( this.inputselectionoption != "" ){
					this.inputselectionoptioninput( { keyCode : 13 }, true )
				}

				

				let self = this

				if( this.editid != null ){



					let update = JSON.stringify( { name:this.inputtextlabel, size:this.inputselectionsize } )

					Portfolioapi.updateParameter( { patch:update, parameterid:this.editid, portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){

						if( result.data.ok ){
							self.addingtype = ""
							self.inputtextlabel = ""
						}
					})


				}

			},

			addselection(){



				if( this.inputselectionoption != "" ){
					this.inputselectionoptioninput( { keyCode : 13 }, true )
				}

				

				if( this.inputselectionlabel != '' && this.inputselectionoptions.length > 0 ){	

					let self = this

					let options = self.inputselectionoptions

					//JSON.stringify( options )

					Portfolioapi.addParameter( { "portfolioid":this.portfolioid, "datatype":"selection", "name":this.inputselectionlabel, "datasize":this.inputselectionsize, "sortorder":0, "active":true, "protected":false, "required":false, "options":JSON.stringify( options ) ,"t":this.token  } ).then( function( result ){

						self.addingtype = ""
						self.inputselectionlabel = ""
						self.inputselectionoptions = []
						self.inputselectionoption = ""
					})


				}
			},

			initaddrow( type ){

				this.inputtextlabel = ""
				this.inputselectionlabel = ""
				this.inputselectionoptions = []
				this.inputtextlabelsize = "small"
				this.inputselectionsize = "selection"
				this.addingtype = type

				this.editrow = {}
				this.editid = null

				this.editopts = []

				let self = this

				setTimeout( function(){
						//self.bindsortables()
					}, 100)

			},

			addnewphase( e ){

				let self = this

				//let newgroup = {"boards":[],"default":false,"name":"New Phase","phase":{},"sortorder":0}				

				/*let newgroup = {}

				newgroup.id = "newphaseid"
				newgroup.active = true
				newgroup.default = false
				newgroup.name = "New phase"
				newgroup.sortorder = 0
				newgroup.portfolioid = this.portfolioid*/

				

				Portfolioapi.addNewGroup( { uuid:uuidv4(), name:"New phase", portfolioid:this.portfolioid, t:this.token, context:this.orgkey } ).then( function( result ){
                        if( result.data.ok ){

                        	//self.origlist.unshift( result.data.group )
                        	self.origlist.push( result.data.group )
                        }
                    })

				//this.inputselectionoption = ""


			},

			inputselectionoptioninput( e, forced ){


				if( e.keyCode == 13 || forced ){				

					let self = this
					

					if( this.editid ){

						Portfolioapi.addoption( { parameterid:this.editid, name:this.inputselectionoption, t:this.token, context:this.orgkey } ).then( function( result ){

							if( result.data.parameter && result.data.parameter.options ){
								self.loadoptions( result.data.parameter.options )
							}

						})

					}else{

						let opt = { "uuid":uuidv4() , "value":this.inputselectionoption }
						this.inputselectionoptions.push( opt )					

					}

					this.inputselectionoption = ""

					//console.log( e )
					//inputselectionoptions 

					/*

					this.inputselectionoptions.push( opt )
					//this.editopts.push( this.inputselectionoption )
					this.inputselectionoption = ''

					this.editopts = this.inputselectionoptions*/
				}

			}

		},

		computed:{

			trydeleteparam(){
				return this.deleteparam!='' ? true : false
			},

			cockpitid(){
        		return this.$route.params.cid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		},
		components:{ pInput, formicon }
	}
	</script>